import React from 'react'
import { VIDEOS_IPHONE } from '../lib/constants'

import { Grid, Divider } from 'semantic-ui-react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Button from '../components/button'

import './for-fun.css'

const ForFunPage = () => (
  <Layout>
    <SEO title="For Fun" />

    <Grid className="for-fun-page" stackable columns={2}>

      <Grid.Column width={5}>
        <h4>For fun...</h4>
        <Button href="../../iphone-summary.pdf">
          <strong style={{ fontSize: '1.25em' }}>What goes inside an iPhone?</strong>
        </Button>
        {VIDEOS_IPHONE.map((video, i) => (
          <Button key={i} href={video.url}>
            Video {i + 1} - {video.title}
          </Button>
        ))}
      </Grid.Column>

    </Grid>

  </Layout >
)

export default ForFunPage
