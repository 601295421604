import { ButtonSection, Video } from "../lib/types"

export const HOME_SECTIONS: ButtonSection[] = [
  {
    sectionTitle: "",
    buttons: [
      {
        title: "Book Features",
        href: "../../book/Pedroni_VHDL_3E_Book_features.pdf",
      },
      {
        title: "Table of Contents",
        href: "../../book/Pedroni_VHDL_3E_Table_of_contents.pdf",
      },
      {
        title: "List of examples and exercises",
        href: "../../book/Pedroni_VHDL_3E_List_examples_and_exercises.pdf",
      },
      {
        title: "VHDL code",
        href: "../../book/Circuit Design with VHDL - VHDL codes.docx",
      },
      {
        title: "Errata and Clarifications",
        href: "../../book/Errata_and_clarifications_rev3.pdf",
      },
    ],
  },
  {
    sectionTitle: "Sample Chapters:",
    buttons: [
      {
        title: "Chapter 4: Review of FPGAs",
        href: "../../book/Pedroni_VHDL_3E_Chapter4.pdf",
      },
      {
        title: "Chapter 10: Concurrent Code",
        href: "../../book/Pedroni_VHDL_3E_Chapter10.pdf",
      },
      {
        title: "Chapter 11: Concurrent Code Practice",
        href: "../../book/Pedroni_VHDL_3E_Chapter11.pdf",
      },
      {
        title: "Video: Quick course on FPGAs",
        href: "https://youtu.be/JA2MlGELd3E",
      },
    ],
  },
  {
    sectionTitle: "Sample Appendices:",
    buttons: [
      {
        title: "A: Vivado Tutorial",
        href: "../../book/Pedroni_VHDL_3E_AppendixA.pdf",
      },
      {
        title: "B: Quartus Prime Tutorial",
        href: "../../book/Pedroni_VHDL_3E_AppendixB.pdf",
      },
      {
        title: "G: I2C Communication Interface",
        href: "../../book/Pedroni_VHDL_3E_AppendixG.pdf",
      },
      {
        title: "I: VGA Video Interface",
        href: "../../book/Pedroni_VHDL_3E_AppendixI.pdf",
      },
      {
        title: "L: Using PLLs with VHDL",
        href: "../../book/Pedroni_VHDL_3E_AppendixL.pdf",
      },
    ],
  },
  {
    sectionTitle: "For Professors and Instructors:",
    buttons: [
      {
        title: "Request Book Copy",
        href: "../../book/MIT_Press_contact.pdf",
      },
      {
        title: "Request VHDL Course Slides",
        subtitle: "(Rev.2)",
        href: "../../book/MIT_Press_contact.pdf",
      },
      {
        title: "Request Solutions Manual",
        subtitle: "(Rev.3)",
        href: "../../book/MIT_Press_contact.pdf",
      },
    ],
  },
]

export const VIDEOS_IPHONE: Video[] = [
  { title: "Introduction", url: "https://youtu.be/dOyIm6G8u3Q" },
  { title: "Energy and main board", url: "https://youtu.be/F5A0CXgvQ7c" },
  { title: "Access", url: "https://youtu.be/kcikbrs8ChQ" },
  { title: "Display and touchscreen", url: "https://youtu.be/rQxXZPCi9c8" },
  { title: "Radios and antennas", url: "https://youtu.be/ZXuCkFsAy_g" },
  { title: "Movement and location", url: "https://youtu.be/eX_cyOIrnAI" },
]

export const PREVIOUS_BOOKS_SECTIONS: ButtonSection[] = [
  {
    sectionTitle: "",
    buttons: [
      {
        title: "Table of Contents",
        href: "../../book/Pedroni_FSMs_Table_of_contents.pdf",
      },
      {
        title: "List of machines in the book",
        href: "../../book/Pedroni_FSMs_List_state_machines.pdf",
      },
      { title: "Errata", href: "../../book/Pedroni_FSMs_Errata.pdf" },
    ],
  },
  {
    sectionTitle: "Sample Chapters:",
    buttons: [
      {
        title: "5: Regular State Machines",
        href: "../../book/Pedroni_FSMs_Chapter5.pdf",
      },
      {
        title: "6: VHDL for Regular State Machines",
        href: "../../book/Pedroni_FSMs_Chapter6.pdf",
      },
    ],
  },
  {
    sectionTitle: "For Professors and Instructors:",
    buttons: [
      { title: "Request book copy", href: "../../book/MIT_Press_contact.pdf" },
      {
        title: "Request solutions manual",
        href: "../../book/MIT_Press_contact.pdf",
      },
    ],
  },
]
