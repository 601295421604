import React from 'react'

import { Button as B } from 'semantic-ui-react'

import './button.css'

interface Props {
  children: any
  href: string
  bold?: boolean
}

const Button: React.FC<Props> = ({ children, href, bold = false }) => {
  return (
    <B className={`button-comp ${bold && 'button-comp--bold'}`} size="mini" as="a" href={href} target="_blank">
      {children}
    </B>
  )
}

export default Button